<template>
  <model-edit :model-data="modelData" @updateProfile="updateProfile">
    <template v-slot:header>
      <site-header
        backRouteName="dashboard"
        backText="Home"
        :title="$_changeModelProperty(modelData, 'name')"
        :sub-title="$_t('subTitle')"
        :login="login"
      ></site-header>
      <h3 style="color:red" v-if="modelData.isReviewByAdmin===true">
        Your changes will be applied after review by the admin
      </h3>
    </template>
  </model-edit>
</template>
<script>
import UserRepository from "../abstraction/repository/userRepository";
import ModelRepository from "../abstraction/repository/modelRepository";
let modelRepository = new ModelRepository();
let userRepository = new UserRepository();
import ModelEdit from "./ModelEdit.vue";
export default {
  components: {
    ModelEdit,
  },
  data() {
    return {
      login: true,
      modelData: {
        loading: false,
      },
    };
  },
  methods: {
    async loadModelData() {
      this.modelData.loading = true;
      this.modelData = await userRepository.myInfo();
      this.modelData.loading = false;
    },
    async updateProfile(form) {
      await modelRepository.update(form);
      // if (response) {
        this.$router.push({
          name: "dashboard",
          params: {
            locale: this.$_getlocaleParam(),
          },
        });
      // }
    },
  },
  created() {
    this.loadModelData();
  },
};
</script>
<style></style>
